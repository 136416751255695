<template>
  <b-card no-body>
    <b-card-body>
      <b-row class="filter">
        <!-- Name -->

        <b-col cols="12" md="3" class="mb-2">
          <label>{{ $t("User Name") }}</label>
          <b-form-input
            v-model="filter.username"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            class="w-100"
          />
        </b-col>
        <b-col cols="12" md="3" class="mb-2">
          <label>{{ $t("User ID") }}</label>
          <b-form-input
            v-model="filter.user_id"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            class="w-100"
          />
        </b-col>
        <!-- Name -->
        <!-- <b-col cols="12" md="3" class="mb-2">
          <label>{{ $t("product_id") }}</label>
          <b-form-input
            v-model="filter.product_id"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            class="w-100"
          />
        </b-col> -->
        <!-- Type -->
        <b-col cols="12" md="3" class="mb-md-0 mb-2">
          <label>{{ $t("Product Name") }}</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            v-model="filter.product_id"
            :options="productIdOptions"
            class="w-100"
            :reduce="(val) => val.value"
          />
          <!-- @input="(val) => $emit('update:bonusCodeIdFilter', val)" -->
        </b-col>
        <!-- Status -->
        <b-col cols="12" md="3" class="mb-md-0 mb-2">
          <label>{{ $t("Bonus Name") }}</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            v-model="filter.bonus_code_id"
            :options="bonusCodeIdOptions"
            class="w-100"
            :reduce="(val) => val.value"
          />
          <!-- @input="(val) => $emit('update:bonusCodeIdFilter', val)" -->
        </b-col>
        <b-col cols="12" md="3" class="mb-2">
          <label>{{ $t("Phone Number") }}</label>
          <b-form-input
            v-model="filter.phone"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            class="w-100"
          />
        </b-col>
        <b-col cols="12" md="3" class="mb-2">
          <label>{{ $t("Ip") }}</label>
          <b-form-input
            v-model="filter.ip"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            class="w-100"
          />
        </b-col>
        <b-col cols="12" md="3" class="mb-2">
          <label>{{ $t("Fromdate") }}</label>
          <flat-pickr
						id="fromDate"
						v-model="filter.fromdate"
						class="form-control"
						placeholder="YYYY-MM-DD"
					/>
        </b-col>
        <!-- action -->
        <b-col cols="12" class="d-flex justify-content-end">
          <b-button variant="outline-secondary" @click="resetFilter()">
            {{ $t("Reset") }}
          </b-button>
          <b-button variant="primary" class="ml-2" @click="searchFilter()">
            {{ $t("Search") }}
          </b-button>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BFormInput,
  BCard,
  BCardBody,
  BRow,
  BCol,
  BButton,BFormGroup
} from "bootstrap-vue";
import { ref } from "@vue/composition-api";
import vSelect from "vue-select";
import flatPickr from 'vue-flatpickr-component'
import store from "@/store";

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BCard,
    BFormInput,
    BCardBody,
    vSelect,flatPickr,BFormGroup
  },
  props: {
    userIdFilter: {
      type: [Number, null],
      default: null,
    },
    productIdFilter: {
      type: [Number, null],
      default: null,
    },
    bonusCodeIdFilter: {
      type: [Number, null],
      default: null,
    },
    userNameFilter: {
      type: [String, null],
      default: null,
    },
    ipFilter: {
      type: [Number, null],
      default: null,
    },
    fromdateFilter: {
      type: [String, null],
      default: null,
    },
    phoneFilter: {
      type: [Number, null],
      default: null,
    },
  },
  methods: {},
  setup(props, { emit }) {
    const productIdOptions = ref();

    const filter = ref({
      username: props.userNameFilter,
      user_id: props.userIdFilter,
      phone: props.phoneFilter,
      fromdate: props.fromdateFilter,
      ip: props.ipFilter,
      product_id: props.productIdFilter,
      bonus_code_id: props.bonusCodeIdFilter,
    });
    const resetFilter = () => {
      emit("update:userNameFilter", null);
      emit("update:userIdFilter", null);
      emit("update:ipFilter", null);
      emit("update:fromdateFilter", null);
      emit("update:phoneFilter", null);
      emit("update:productIdFilter", null);
      emit("update:bonusCodeIdFilter", null);
      filter.value = {
        username: null,
        user_id: null,
        ip: null,
        phone: null,
        fromdate: null,
        product_id: null,
        bonus_code_id: null,
      };
    };

    const searchFilter = () => {
      emit("update:userNameFilter", filter.value.username);
      emit("update:userIdFilter", filter.value.user_id);
      emit("update:ipFilter", filter.value.ip);
      emit("update:fromdateFilter", filter.value.fromdate);
      emit("update:phoneFilter", filter.value.phone);


      emit("update:productIdFilter", filter.value.product_id);
      emit("update:bonusCodeIdFilter", filter.value.bonus_code_id);
    };
    store.dispatch("bonus/fetchProductID").then((response) => {
      productIdOptions.value = response.data.data.map((val) => {
        return {
          label: val.name,
          id: val.id,
        };
      });
    });
    const bonusCodeIdOptions = [
      { label: "THƯỞNG LÊN TỚI 150% KHUYẾN MÃI GỬI TIỀN LẦN ĐẦU", value: 6 },
      { label: " 100K TIỀN CƯỢC MIỄN PHÍ CHO THÀNH VIÊN MỚI", value: 7 },
    ];
    return {
      resetFilter,
      searchFilter,
      filter,
      productIdOptions,
      bonusCodeIdOptions,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
