<template>
  <div>
    <bonus-Manager-filters
      :userIdFilter.sync="userIdFilter"
      :userNameFilter.sync="userNameFilter"
      :ipFilter.sync="ipFilter"
      :fromdateFilter.sync="fromdateFilter"
      :phoneFilter.sync="phoneFilter"
      :product-id-filter.sync="productIdFilter"
      :bonus-code-id-filter.sync="bonusCodeIdFilter"
      @refetch-data="refetchData"
    />
    <b-card no-body>
      <div class="p-1" v-if="$can('export', 'userbonus')">
        <!-- Table Top -->
        <b-row>
          <b-col cols="12" class="d-flex justify-content-end">
            <!-- md="3" -->
            <b-button
              v-if="!showLoadingExport"
              variant="primary"
              @click="exportData"
            >
              <feather-icon icon="DownloadIcon" size="16" /> {{ $t("Export") }}
            </b-button>
            <b-button v-else variant="primary" disabled class="mr-1">
              <b-spinner small />
              <feather-icon icon="DownloadIcon" size="16" />
              {{ $t("Exporting") }}
            </b-button>
            <div class="hidden">
              <vue-export-excel
                ref="exportButton"
                :data="data"
                :columns="columns"
                :file-name="exportFiletitle"
                :file-type="'xlsx'"
                :sheet-name="exportFileSheetName"
              >
                <feather-icon icon="DownloadIcon" size="16" />
                {{ $t("Export") }}
              </vue-export-excel>
            </div>
          </b-col>
        </b-row>
      </div>
      <b-table
        ref="refBonusManagerTable"
        class="position-relative table-white-space mb-0 max-height-table"
        sticky-header
        hover
        head-variant="light"
        :no-border-collapse="true"
        :items="fetchBonusCodesManager"
        responsive
        :fields="tableColumns"
        primary-key="index"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <template #cell(index)="data">
          {{ perPage * (currentPage - 1) + data.index + 1 }}
        </template>
        <template #cell(username)="data">
          <b-link
            @click="viewBonusModal(data.item)"
            class="cursor-pointer"
            v-if="data.item.user"
          >
            {{  data.item.user ? data.item.user.username : ""}}
          </b-link>
        </template>
        <template #cell(user_id)="data">
          {{ data.item.user_id }}
        </template>

        <template #cell(rollover)="data">
          {{ data.item.rollover }}
        </template>

        <template #cell(amount)="data">
          <div v-if="data.item.amount">
            {{ formatCurrency(data.item.amount) }}
          </div>
          <div v-else>0</div>
        </template>

        <template #cell(amount_bonus)="data">
          <div v-if="data.item.amount_bonus">
            {{ formatCurrency(data.item.amount_bonus) }}
          </div>
          <div v-else>0</div>
        </template>

        <template #cell(product_name)="data">
          <div v-if="data.item.product">
            {{ data.item.product.name }}
          </div>
        </template>
        <template #cell(product_code)="data">
          <div v-if="data.item.bonus_code">
            {{ data.item.bonus_code.name }}
          </div>
        </template>
        <template #cell(ip)="data">
          {{ data.item.ip }}
        </template>
        <template #cell(time_start)="data">
          {{ data.item.time_start }}
        </template>
        <template #cell(status)="data">
          <div :class="`text-capitalize align-items-center d-flex`">
            <span
              :class="`dot bg-${resolveStatus(data.item.status).variant}`"
            />{{ resolveStatus(data.item.status).label }}
          </div>
        </template>

        <template #cell(createdAt)="data">
          {{ formatDateTime(data.item.created_at).replace(".000000Z", "") }}
        </template>

        <template #cell(updatedAt)="data">
          {{ formatDateTime(data.item.updated_at).replace(".000000Z", "") }}
        </template>
        <template #cell(action)="data">
          <!-- <b-link
						v-b-tooltip.hover.v-info
						:title="$t('Edit')"
						class="font-weight-bold text-nowrap mr-1"
						style="cursor: pointer;"
						@click="showEditBonusModal(data.item)"
						v-if="$can('edit', 'bonuscode')"
					>
						Edit
					</b-link> -->
          <b-link
            v-b-tooltip.hover.v-info
            class="font-weight-bold text-nowrap mr-1"
            style="cursor: pointer"
            @click="viewBonusModal(data.item)"
            v-if="$can('view', 'bonuscode')"
          >
            <feather-icon icon="EyeIcon" />
            <span class="align-middle ml-50">{{ $t("View") }}</span>
          </b-link>
          <b-link
            class="font-weight-bold text-nowrap mr-1"
            style="cursor: pointer"
            @click="viewUpdateExpireBonusModal(data.item)"
            v-if="$can('edit', 'bonuscode')"
          >
            <feather-icon icon="EditIcon" />
            <span class="align-middle ml-50">{{ $t("Edit") }}</span>
          </b-link>
        </template>
      </b-table>
      <div class="p-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-start
            "
          >
            <span class="text-muted"
              >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-end
            "
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalBonus"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <!-- Add Bonus Modal -->
    <!-- <add-bonus-modal
      :bonus-code-options="bonusCodeOptions"
      :group-name-options="groupNameOptions"
      :currency-options="currencyOptions"
      :product-options="productOptions"
      :type-options="typeOptions"
      :bonus-type-options="bonusTypeOptions"
      @refetch-data="refetchData"
    /> -->
    <!-- Edit Bonus Modal -->
    <!-- <edit-bonus-modal
      :bonus-detail.sync="bonusDetail"
      :bonus-code-options="bonusCodeOptions"
      :group-name-options="groupNameOptions"
      :currency-options="currencyOptions"
      :product-options="productOptions"
      :type-options="typeOptions"
      :bonus-type-options="bonusTypeOptions"
      @refetch-data="refetchData"
    /> -->
    <view-bonus-modal
      :bonus-detail.sync="bonusDetail"
      @refetch-data="refetchData"
    />
    <view-update-expire-bonus-modal
      :bonus-detail.sync="bonusDetail"
      @refetch-data="refetchData"
    />
  </div>
</template>

<script>
import {
  BButton,
  BModal,
  VBModal,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BTable,
  BTr,
  BTh,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BFormGroup,
  VBTooltip,
  BSpinner,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { ref, watch, computed } from "@vue/composition-api";
import {
  formatDateTime,
  numberFormat,
  formatTimeIso,
} from "@core/utils/filter";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import store from "@/store";
import bonusStoreModule from "@/views/bonus/bonusStoreModule";
import currencyStoreModule from "@/views/local-setting/local/currencyStoreModule";
import BonusManagerFilters from "./BonusManagerFilters.vue";
import VueExportExcel from "@/views/extensions/export/VueExportExcel.vue";

export default {
  components: {
    BonusManagerFilters,
    ViewBonusModal: () => import("./ViewBonusModal.vue"),
    ViewUpdateExpireBonusModal: () => import("./ViewUpdateExpireBonusModal.vue"),
    // AddBonusModal: () => import("./AddBonusModal.vue"),
    // EditBonusModal: () => import("./EditBonusModal.vue"),
    VueExportExcel,
    BButton,
    BFormGroup,
    BModal,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BTr,
    BTh,
    BMedia,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BSpinner,
  },
  directives: {
    "b-tooltip": VBTooltip,
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      roleId: null,
      content: null,
      bonusDetail: null,
      showLoadingExport: false,
      columns: [
        { label: "st", field: "id" },
        // { label: "Transaction ID", field: "ref" },
        { label: "Username", field: "user", dataFormat: this.showUsername },
        { label: "User Id", field: "user_id" },
        { label: "Rollover", field: "rollover" },
        {
          label: "Amount",
          field: "amount",
          dataFormat: (value) => value,
        },
        {
          label: "Product Name",
          field: "product",
          dataFormat: this.showName,
        },
        {
          label: "Product Code",
          field: "bonus_code",
          dataFormat: this.showName,
        },
        { label: "Ip", field: "ip" },
        { label: "Time Start", field: "time_start" },
        // { label: "Full Name", field: "user", dataFormat: this.showName },
        // {
        //   label: "Company Bank Account Code",
        //   field: "v_bank",
        //   dataFormat: this.resolveVBankExport,
        // },
        // { label: "Member Bank Acc Name", field: "account_name" },
        // { label: "Member Bank Acc No", field: "account_number" },
        // { label: "Member Bank Ref No", field: "bank_ref" },
        // { label: "Currency", field: "currency" },

        // { label: "Fee", field: "fee", dataFormat: (value) => value / 1000 },
        {
          label: "Status",
          field: "status",
          dataFormat: (value) => this.resolveStatus(value).label,
        },
        {
          label: "Created At",
          field: "created_at",
          dataFormat: (value) => formatDateTime(value).replace(".000000Z", ""),
        },
        // {
        //   label: "Updated At",
        //   field: "updated_at",
        //   dataFormat: (value) => formatDateTime(value).replace(".000000Z", ""),
        // },
      ],
      data: [],
      exportFiletitle: `BonusManager-${new Date()
        .toJSON()
        .replace(":", "-")
        .substring(0, 16)}`,
      exportFileSheetName: "BonusManager",
    };
  },
  mounted() {},
  methods: {
    viewBonusModal(datas) {
      this.bonusDetail = datas;
      this.$bvModal.show("modal-view-bonus-detail");
    },
    viewUpdateExpireBonusModal(datas) {
      this.bonusDetail = datas;
      this.$bvModal.show("modal-view-update-expire-bonus-detail");
    },
    showUsername(value) {
      return value ? value.username : "";
    },
    showName(value) {
      return value ? value.name : "";
    },
    async getExportData() {
      await store
        .dispatch("bonus/exportBonusManager", {
          // page: pageNumber,
          // type: this.typeFilter,
          // username: this.usernameFilter,
          // reference: this.referenceFilter,
          // fromdate: this.fromdateFilter,
          // todate: this.todateFilter,
          // bankId: this.bankFilter,
          // stat: this.statusFilter,
        })
        .then((response) => {
          console.log(123, response);
          if (response.data.code == "00") {
            this.data = response.data.data;
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: response.data.message,
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          }
        });
    },
    async exportData() {
      this.showLoadingExport = true;
      await this.getExportData();
      await this.delay(2000);
      // }
      this.showLoadingExport = false;
      this.$refs.exportButton.$el.click();
    },
    delay(ms) {
      return new Promise((resolve, reject) => setTimeout(resolve, ms));
    },
  },
  setup() {
    if (!store.hasModule("local-setting-currency"))
      store.registerModule("local-setting-currency", currencyStoreModule);
    if (!store.hasModule("bonus"))
      store.registerModule("bonus", bonusStoreModule);
    const toast = useToast();
    const bonusManagers = ref([]);
    const perPage = ref(25);
    const totalBonus = ref(0);
    const currentPage = ref(1);
    const sortBy = ref("id");
    const isSortDirDesc = ref(true);
    const userIdFilter = ref(null);
    const productIdFilter = ref(null);
    const bonusCodeIdFilter = ref(null);
    const discountTypeFilter = ref(null);
    const userNameFilter = ref(null);

    const phoneFilter = ref(null);
    const ipFilter = ref(null);
    const fromdateFilter = ref(null);
    const todateFilter = ref(null);
    const refBonusManagerTable = ref(null);
    const groupNameOptions = ref([]);
    const currencyOptions = ref([]);
    const productOptions = ref([]);
    const bonusCodeOptions = ref(null);
    const bonusTypeOptions = ref([]);
    // const user_id =ref

    const tableColumns = [
      { key: "index", label: "#" },
      { key: "username", label: "username" },
      { key: "user_id", label: "User Id" },
      { key: "rollover", label: "Rollover" },
      { key: "amount", label: "Amount" },
      { key: "amount_bonus", label: "Amount Bonus" },
      { key: "product_name", label: "Product Name" },
      // { key: "bonus_name", label: "Bonus Name" },
      { key: "product_code", label: "Bonus Name" },
      { key: "ip", label: "IP" },
      { key: "time_start", label: "Time Start" },
      { key: "createdAt", label: "Created At" },
      { key: "updatedAt", label: "Updated At" },
      { key: "action", label: "Action" },
    ];

    const refetchData = () => {
      refBonusManagerTable.value.refresh();
    };
    const formatCurrency = (amount) => {
      const floatNumber = parseFloat(amount) / 1000;
      return floatNumber.toLocaleString("vi-VN");
    };

    watch(
      [
        currentPage,
        userIdFilter,
        productIdFilter,
        bonusCodeIdFilter,
        userNameFilter,
        ipFilter,
        // discountTypeFilter,
        phoneFilter,
        fromdateFilter,
        // todateFilter,
      ],
      () => {
        refetchData();
      }
    );
    const fetchBonusCodesManager = (ctx, callback) => {
      store
        .dispatch("bonus/fetchBonusCodesManager", {
          page: currentPage.value,
          user_id: userIdFilter.value,
          username: userNameFilter.value,
          phone: phoneFilter.value,
          fromDate: fromdateFilter.value,
          ip: ipFilter.value,
          product_id: productIdFilter.value,
          bonus_code_id: bonusCodeIdFilter.value,
        })
        .then((response) => {
          if (response.data.code == "00") {
            // bonusCodeOptions.value = response.data.data;
            callback(response.data.data);
            totalBonus.value = response.data.count;
            perPage.value =
              currentPage.value == 1 ? response.data.data.length : 25;
          } else {
            toast({
              component: ToastificationContent,
              props: {
                title: response.data.message,
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          }
        });
      // .catch((error) => {
      //   const { response } = error;
      //   toast({
      //     component: ToastificationContent,
      //     props: {
      //       title: response.data.message || "Error fetching cash logs list",
      //       icon: "AlertTriangleIcon",
      //       variant: "danger",
      //     },
      //   });
      // });
    };

    const dataMeta = computed(() => {
      const localItemsCount = refBonusManagerTable.value
        ? refBonusManagerTable.value.localItems.length
        : 0;
      return {
        from:
          perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
        to: perPage.value * (currentPage.value - 1) + localItemsCount,
        of: totalBonus.value,
      };
    });

    const statusOptions = [
      { label: "Active", value: 1 },
      { label: "inActive", value: 2 },
    ];
    const productIdOptions = [
      { label: "THƯỞNG LÊN TỚI 150% KHUYẾN MÃI GỬI TIỀN LẦN ĐẦU", value: 6 },
      { label: " 100K TIỀN CƯỢC MIỄN PHÍ CHO THÀNH VIÊN MỚI", value: 7 },
    ];

    const resolveStatus = (status) => {
      if (status == 1) return { label: "Active", variant: "primary" };
      if (status == 2) return { label: "inActive", variant: "danger" };
      return { label: "Pending", variant: "secondary" };
    };

    const resolveType = (type) => {
      if (type == 1) return "Cash";
      if (type == 2) return "Percent";
      return "-";
    };

    const resolveBonusStatus = (start, end) => {
      const startDate = new Date(start);
      const endDate = new Date(end);
      const now = new Date();
      if (startDate <= now && now <= endDate)
        return { label: "Live", variant: "primary" };
      if (now > endDate) return { label: "Expired", variant: "danger" };
      if (now < startDate) return { label: "Coming", variant: "secondary" };
      return { label: "-", variant: "secondary" };
    };

    const typeOptions = [
      { label: "Cash", value: 1 },
      { label: "Percent", value: 2 },
    ];
    return {
      tableColumns,
      perPage,
      currentPage,
      totalBonus,
      dataMeta,
      sortBy,
      isSortDirDesc,
      refBonusManagerTable,

      bonusManagers,
      fetchBonusCodesManager,
      refetchData,

      userIdFilter,
      groupNameOptions,
      bonusCodeIdFilter,
      currencyOptions,
      productIdFilter,
      productOptions,
      discountTypeFilter,
      fromdateFilter,
      todateFilter,
      phoneFilter,

      resolveStatus,
      resolveBonusStatus,
      statusOptions,
      resolveType,
      typeOptions,
      bonusCodeOptions,
      bonusTypeOptions,

      formatDateTime,
      numberFormat,
      formatCurrency,
      productIdOptions,
      formatTimeIso,
      userNameFilter,
      ipFilter,
    };
  },
};
</script>
<style>
.b-table-sticky-column {
  right: 0 !important;
}
td.b-table-sticky-column {
  background-color: inherit;
}
</style>
